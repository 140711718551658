import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const query = graphql`
  {
    allContentfulBanner(
      filter: { sideBanner: { eq: true } }
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        link
        banner {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        title
      }
    }
  }
`;

const aniSmooth = "transition-all duration-[0.3s] ease-in-out";

const BannerSide = () => {
  const {
    allContentfulBanner: { nodes: banners },
  } = useStaticQuery(query);
  return (
    <div className="rounded-2xl ">
      {banners.map((item, index) => {
        const { banner, link, title } = item;
        const pathToImage = getImage(banner);

        return (
          <a
            href={link}
            key={index}
            className={`${aniSmooth} hover:scale-105 w-full object-contain rounded-2xl`}
          >
            <GatsbyImage
              image={pathToImage}
              alt={title}
              className={`${aniSmooth} hover:scale-105 hover:brightness-110 object-contain mb-6 mr-6 rounded-2xl h-60 aspect-square group-hover:brightness-[100%] brightness-[90%]`}
            />
          </a>
        );
      })}
    </div>
  );
};

export default BannerSide;
