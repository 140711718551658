import React from "react";
import PostList from "../../components/PostList";
import { graphql, useStaticQuery } from "gatsby";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ButtonUp from "../../utilities/ButtomUp";
import EditorPosts from "../../components/EditorPosts";
import { BiNews } from "@react-icons/all-files/bi/BiNews";
import { GoBookmark } from "@react-icons/all-files/go/GoBookmark";
import Seo from "../../components/Seo";
import FilterCate from "../../components/FilterCate";
import BannerSide from "../../components/BannerSide";

const query = graphql`
  {
    allContentfulPost(sort: { fields: date, order: DESC }) {
      totalCount
      nodes {
        id
        title
        date(formatString: "DD MMMM, YYYY")
        subPath
        description {
          description
        }
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        tagsBiodiversity
        tagsBusinessAndSustainability
        tagsClimateChange
        tagsEnglishNews
        tagsGreenInnovation
        tagsNaturalDisaster
        tagsPlasticAndWaste
        tagsWildlife
      }
    }
  }
`;

const Index = () => {
  const {
    allContentfulPost: { nodes: posts, totalCount: total },
  } = useStaticQuery(query);

  return (
    <div className="w-full relative overflow-hidden bg-stone-50">
      <Seo title="Publication" pathname="/publication/" />
      <div id="top" />
      <Navbar className="relative z-10" />
      <div id="latest"></div>
      <div className="relative z-20 flex flex-col justify-center items-center ">
        <div className="w-full 2xl:w-[1440px] px-4 md:px-14 h-full flex flex-col justify-center items-center">
          <div className="flex flex-col lg:flex-row w-full mb-8 border-stone-300 border-b-2">
            <div className="w-full lg:w-[70%] flex flex-col justify-start items-start">
              <div className="font-cardo font-bold text-3xl text-stone-900 mb-5 flex">
                <div className="w-[7px] h-[35px] bg-green-120 opacity-80 mr-4"></div>
                All Articles
              </div>

              <div className="flex mb-6 lg:mb-10 border-t-2 border-stone-300 w-full lg:w-[90%] pt-5">
                <div className="font-cardo text-xl flex flex-col lg:flex-row justify-start items-start">
                  <div className="flex justify-center items-center font-bold">
                    <BiNews className="mr-3 mb-0.5 text-stone-800 text-xl" />
                    Categories:
                  </div>

                  <FilterCate />
                </div>
              </div>

              <PostList
                posts={posts}
                total={total}
                anchor={"publication/#latest"}
              />
            </div>
            <div className="w-full lg:w-[30%] flex flex-col justify-start items-start">
              <div className="font-cardo font-bold text-3xl text-green-120 mb-5 flex">
                <div className="w-[7px] h-[35px] bg-green-120 opacity-80 mr-4"></div>
                <div className="font-cardo font-bold text-3xl text-stone-900 cursor-default flex justify-center items-center">
                  Our Sponsor
                  <div className="flex justify-center items-center">
                    <div className="h-[2px] w-[20px] bg-stone-200 ml-3"></div>
                    <div className="bg-stone-400 font-jarkatar text-xs text-white px-2 py-0.5 rounded ml-3">
                      Ad
                    </div>
                  </div>
                </div>
              </div>

              <BannerSide />

              <div className="font-cardo font-bold text-3xl text-green-120 mb-0 lg:mb-4 mt-8 flex border-b-2 border-stone-300 w-full pb-6">
                <div className="w-[7px] h-[35px] bg-green-120 opacity-80 mr-4"></div>
                <div className="font-cardo font-bold text-3xl text-stone-900 cursor-default flex justify-center items-center">
                  Editor's Picks
                  <GoBookmark className="text-xl ml-1.5 mt-0 text-orange-400" />
                </div>
              </div>
              <div className="w-full mt-6 md:mt-3">
                <EditorPosts />
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>

      <ButtonUp link={"publication/#top"} />
    </div>
  );
};

export default Index;
