import React, { useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import slugify from "slugify";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { AiFillCaretLeft } from "@react-icons/all-files/ai/AiFillCaretLeft";
import { AiFillCaretRight } from "@react-icons/all-files/ai/AiFillCaretRight";
import Tags from "./Tags";

const hover = "transition-all ease-in-out duration-200 ";

const PostList = ({ posts = [], total, anchor }) => {
  const [indexN, setIndexN] = useState(0);
  const postPerPage = 6;
  const next = () => {
    indexN + 1 >= Math.ceil(total / postPerPage)
      ? setIndexN((prevEff) => prevEff)
      : setIndexN((prevEff) => prevEff + 1);
  };

  const back = () => {
    indexN + 1 <= 1
      ? setIndexN((prevEff) => prevEff)
      : setIndexN((prevEff) => prevEff - 1);
  };

  return (
    <div className="w-full">
      <div className={`flex flex-col`}>
        {posts
          .slice(indexN * postPerPage, (indexN + 1) * postPerPage)
          .map((post) => {
            const {
              id,
              title,
              subPath,
              image,
              date,
              tagsBiodiversity,
              tagsBusinessAndSustainability,
              tagsClimateChange,
              tagsEnglishNews,
              tagsGreenInnovation,
              tagsNaturalDisaster,
              tagsPlasticAndWaste,
              tagsWildlife,
              description: { description },
            } = post;
            const pathToImage = getImage(image);
            const slug = slugify(subPath, { lower: true });
            return (
              <div
                key={id}
                className={`${hover} scale-[97%] group mb-6 lg:mb-8 relative h-full w-full lg:w-[90%] px-4 lg:px-8 py-4 lg:py-8 bg-white sm:rounded-xl drop-shadow-lg shadow-sm hover:shadow-lg shadow-stone-300`}
              >
                <div
                  className={`${hover} relative z-10 w-full object-contain aspect-square sm:aspect-auto flex flex-col sm:flex-row justify-start items-start`}
                >
                  <Link
                    to={`/${slug}/`}
                    className="object-contain h-full w-[100%] sm:w-[30%] aspect-square px-3 py-3 sm:px-0 sm:py-0 mr-4 lg:mr-6"
                  >
                    <GatsbyImage
                      image={pathToImage}
                      alt={title}
                      className={`${hover} object-contain h-full w-full group-hover:brightness-[100%] brightness-[90%]`}
                    />
                  </Link>

                  <div className="flex flex-col w-[100%] sm:w-[70%] px-3 py-2 sm:px-0 sm:py-0 justify-start items-start">
                    <Link
                      to={`/${slug}/`}
                      className={`${hover} text-lg font-headlao hover:text-green-120 text-ellipsis overflow-hidden mt-0 lg:mb-3 text-left h-14`}
                    >
                      {title}
                    </Link>

                    <div className="w-full font-bodylao text-sm text-ellipsis overflow-hidden hidden lg:flex h-8 lg:h-16 mb-2 text-justify font-base text-stone-400">
                      {description}
                    </div>

                    <div className="w-full flex flex-col lg:flex-row justify-start items-start pb-1.5 mt-2 lg:mt-3 ">
                      <div className="flex justify-start items-center w-full lg:w-1/2 mb-4 lg:mb-0">
                        <div className="h-[2px] w-[25px] bg-stone-200 mr-2 rounded-xl"></div>
                        <div className="font-jarkatar font-base text-xs text-stone-400">
                          {date}
                        </div>
                      </div>
                      <Tags
                        tagsBiodiversity={tagsBiodiversity}
                        tagsBusinessAndSustainability={
                          tagsBusinessAndSustainability
                        }
                        tagsClimateChange={tagsClimateChange}
                        tagsEnglishNews={tagsEnglishNews}
                        tagsGreenInnovation={tagsGreenInnovation}
                        tagsNaturalDisaster={tagsNaturalDisaster}
                        tagsPlasticAndWaste={tagsPlasticAndWaste}
                        tagsWildlife={tagsWildlife}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <div
        className={`${
          Math.ceil(total / postPerPage) > 1 ? "flex" : "hidden"
        } w-full lg:w-[90%] mb-16 flex justify-center items-center`}
      >
        <div
          className={`${hover} flex justify-between items-center bg-white w-full lg:w-[70%] drop-shadow-lg shadow-sm hover:shadow-lg shadow-stone-300 py-2 px-3 rounded-full`}
        >
          <button
            className={`${hover} ${indexN <= 0 ? "invisible" : "visible"}`}
            onClick={() => back()}
            aria-label="back"
          >
            <AnchorLink
              to={`/${anchor}`}
              className={`${hover} pl-4 pr-8 py-3.5 rounded-full bg-green-110 hover:bg-green-120 flex justify-center items-center font-jarkatar font-medium text-white text-sm lg:text-base`}
            >
              <AiFillCaretLeft className="mr-1 text-xl" /> Back
            </AnchorLink>
          </button>

          <div className="flex lg:hidden font-jarkatar font-medium text-base">
            Page {indexN + 1} / {Math.ceil(total / postPerPage)}
          </div>

          <div className="hidden lg:flex font-jarkatar font-medium text-base">
            Page {indexN + 1} out of {Math.ceil(total / postPerPage)}
          </div>

          <button
            className={`${hover} ${
              indexN + 1 >= Math.ceil(total / postPerPage)
                ? "invisible"
                : "visible"
            }`}
            onClick={() => next()}
            aria-label="next"
          >
            <AnchorLink
              to={`/${anchor}`}
              className={`${hover} pl-8 pr-4 py-3.5 rounded-full bg-green-110 hover:bg-green-120 flex justify-center items-center font-jarkatar font-medium text-white text-sm lg:text-base`}
            >
              Next <AiFillCaretRight className="ml-1 text-xl" />
            </AnchorLink>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostList;
