import React from "react";
import { Link } from "gatsby";
const hover = "transition-all ease-in-out duration-200 ";

const FilterCate = () => {
  return (
    <nav className="lg:ml-6 mt-4 lg:mt-0 flex flex-wrap justify-start items-start">
      <Link
        to="/publication/"
        className={`${hover} mb-3 mr-3 text-sm md:text-base font-jarkatar font-medium bg-white hover:bg-green-120 hover:text-white py-2 px-4 rounded-xl shadow drop-shadow-md cursor-pointer`}
        activeClassName={`text-white bg-green-110 hover:bg-green-110`}
      >
        All
      </Link>
      <Link
        to="/publication/englishnews/"
        className={`${hover} mb-3 mr-3 text-sm md:text-base font-jarkatar font-medium bg-white hover:bg-green-120 hover:text-white py-2 px-4 rounded-xl shadow drop-shadow-md cursor-pointer`}
        activeClassName={`text-white bg-green-110 hover:bg-green-110`}
      >
        English News
      </Link>
      <Link
        to="/publication/climatechange/"
        className={`${hover} mb-3 mr-3 text-sm md:text-base font-jarkatar font-medium bg-white hover:bg-green-120 hover:text-white py-2 px-4 rounded-xl shadow drop-shadow-md cursor-pointer`}
        activeClassName={`text-white bg-green-110 hover:bg-green-110`}
      >
        Climate Change
      </Link>
      <Link
        to="/publication/wildlife/"
        className={`${hover} mb-3 mr-3 text-sm md:text-base font-jarkatar font-medium bg-white hover:bg-green-120 hover:text-white py-2 px-4 rounded-xl shadow drop-shadow-md cursor-pointer`}
        activeClassName={`text-white bg-green-110 hover:bg-green-110`}
      >
        Wildlife
      </Link>
      <Link
        to="/publication/plasticandwastepollution/"
        className={`${hover} mb-3 mr-3 text-sm md:text-base font-jarkatar font-medium bg-white hover:bg-green-120 hover:text-white py-2 px-4 rounded-xl shadow drop-shadow-md cursor-pointer`}
        activeClassName={`text-white bg-green-110 hover:bg-green-110`}
      >
        Plastic And Waste Pollution
      </Link>
      <Link
        to="/publication/naturaldisaster/"
        className={`${hover} mb-3 mr-3 text-sm md:text-base font-jarkatar font-medium bg-white hover:bg-green-120 hover:text-white py-2 px-4 rounded-xl shadow drop-shadow-md cursor-pointer`}
        activeClassName={`text-white bg-green-110 hover:bg-green-110`}
      >
        Natural Disaster
      </Link>
      <Link
        to="/publication/biodiversity/"
        className={`${hover} mb-3 mr-3 text-sm md:text-base font-jarkatar font-medium bg-white hover:bg-green-120 hover:text-white py-2 px-4 rounded-xl shadow drop-shadow-md cursor-pointer`}
        activeClassName={`text-white bg-green-110 hover:bg-green-110`}
      >
        Biodiversity
      </Link>
      <Link
        to="/publication/businessandsustainability/"
        className={`${hover} mb-3 mr-3 text-sm md:text-base font-jarkatar font-medium bg-white hover:bg-green-120 hover:text-white py-2 px-4 rounded-xl shadow drop-shadow-md cursor-pointer`}
        activeClassName={`text-white bg-green-110 hover:bg-green-110`}
      >
        Business And Sustainability
      </Link>
      <Link
        to="/publication/greeninnovation/"
        className={`${hover} mb-3 mr-3 text-sm md:text-base font-jarkatar font-medium bg-white hover:bg-green-120 hover:text-white py-2 px-4 rounded-xl shadow drop-shadow-md cursor-pointer`}
        activeClassName={`text-white bg-green-110 hover:text-stone-50`}
      >
        Green Innovation
      </Link>
    </nav>
  );
};

export default FilterCate;
